import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import DailyOperationProductionTrendChartSkeleton from './DailyOperationProductionTrendChartSkeleton';
import moment from 'moment'
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';

const DailyOperationProductionTrendChart = (props) => {
  const {t, i18n} = useTranslation(['wtw', 'common']);  
  const [Loading, setLoading] = useState(true);
  const [ChartCategories, setChartCategories] = useState([]);
  const [ChartSeries, setChartSeries] = useState([]);
  const [ChartStep, setChartStep] = useState(0);  
  const [isGanttFullScreenMode, setisGanttFullScreenMode] = useState(false);
   
  const getChart = () => {    
    setupChartBars(props.data,7);
    setLoading(false);
  };
  
  const toggleFullScreen = () => {
    setisGanttFullScreenMode(!isGanttFullScreenMode);
  };

  const chartBarsOptions = {
    chart: {
      type: 'column',
      height: isGanttFullScreenMode ? 550 : 250,
      events: {
        load() {
            const chart = this; 
           
            // X
            const xAxis = chart.xAxis[0];
            const xExtremes = xAxis.getExtremes();
            const minXValue = xExtremes.dataMin;  
            const maxXValue = xExtremes.dataMax;  
             
             let xTickInterval;
             if (maxXValue < 10) {
                 xTickInterval =  1; // Si el valor máximo es menor a 10, es 1
             } else if (maxXValue >= 10 && maxXValue < 20) {
                 xTickInterval = 2; // Si el valor máximo es mayor a 10 pero menor a 20, = 2
             } else if (maxXValue > 20) {
                 xTickInterval = 4; // Si el valor máximo es mayor a 20, = 4
             }
           
             xAxis.update({
                 min: minXValue,
                 max: maxXValue,
                 tickInterval: xTickInterval 
             });
            
             // Y
             const yAxis = chart.yAxis[0];
             const yExtremes = yAxis.getExtremes();
             const minYValue = yExtremes.dataMin;  
             const maxYValue = yExtremes.dataMax;  
       
            const rangeYValue = maxYValue - minYValue;  
          
            let yTickInterval = 0; 
            yTickInterval = Math.ceil(rangeYValue / 4);   
  
            yAxis.update({
              min: minYValue,
              max: maxYValue,
              tickInterval: yTickInterval 
          });
 
        }
    }
    },
    title: {
      text: props.nomIndicador,
    },
    subtitle: {
      text: props.title,
    },
    plotOptions: {
      series: {
        lineWidth: 1
      }
    },
    xAxis: {
      categories: ChartCategories,
    
    },
    yAxis: {
      title: {
        text: ''
      },
      tickInterval: null,
      labels: {
        formatter: (item) => {
          return `${formatValue(item.value, props.tipoIndicador)}`;
        }
      }
    },
    tooltip: {
      formatter() {
        const { series, point } = this;
        const value = series.type === 'column' ? point.y : point.y;
        const date = moment(point.category).format('MMM D');
        return `${date} - ${series.name}: ${formatValue(value, props.tipoIndicador)}`;
      }
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal'
    },
    series: ChartSeries,
  };
  

  const setupChartBars = (report, step) => {
    let categories = [];
    const series = [];
    
    if (report) {      
      categories = report.map(({ Date }) => moment(Date).format('MMM D'));          
      
      series.push({
        type: 'column', // Serie de barra
        name: props.nomIndicador,
        data: report.map(({ Valor }) => Valor),
        color: '#2CAFFE'
      });
      series.push({
        type: 'line', // Serie de Linea
        name: 'Daily Avg',
        data: report.map(({ PromedioDiarioMes }) => PromedioDiarioMes),
        dashStyle: 'Dash',
        color: '#000',
        marker: {
          enabled: false, 
        }
      });
    }
  
    setChartCategories(categories);
    setChartSeries(series);  
    setChartStep(step);  
  };

  useEffect(() => {      
    getChart();
  }, [
    props.refresh
  ]);

  function formatValue(value, tipoValorIndicador) {
   
    let formattedResult;
    switch (tipoValorIndicador) {
      case 1:
        formattedResult = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        break;
      case 2:
        formattedResult = value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        break;
      case 3:
        formattedResult = `${(value).toFixed(1)}%`;
        break;
      default:
        formattedResult = value;
    }
    return formattedResult;
  }

  const onDetail = () => {
    if (props.onViewDetail !=  null){ 
       props.onViewDetail(props.nomIndicador);
     }
  };

  const chartTemplate = (
    <>
      <HighchartsReact
        highcharts={Highcharts} 
        options={chartBarsOptions} 
      />    
    </>
  );

  const screenRender = () => {
    return (
      <>
        {
          Loading? <DailyOperationProductionTrendChartSkeleton /> : (
            <div>
              <Row>
                <Col>
                  <div onClick={props.onViewDetail ? onDetail : undefined} hidden={props.onViewDetail===undefined}>
                    <span className='link-text primary-color'> 
                      View detail by asset / cell 
                    </span>
                  </div>                   
                </Col>
                <Col className='text-right'>
                  <FieldButton
                    type="button" 
                    onClick={toggleFullScreen}
                    icon={isGanttFullScreenMode?"fas fa-compress":"fas fa-expand"}
                    className="p-button-secondary no-border p-mr-2 p-button-rounded p-button-text devextreme-gantt-toolbar-button"
                    style={{ marginRight: '.25em' }}
                    title={isGanttFullScreenMode?t('wtw:TurnScreenMode'):t('wtw:FullScreenMode')}
                  />                     
                </Col>
              </Row>  
              <Row className='align-items-start' style={isGanttFullScreenMode? {height:'100%'}: null}>
                <Col xs={12} style={isGanttFullScreenMode? {height:'100%', padding: '0px'}: null}>
                  {chartTemplate}                     
                </Col>
              </Row> 
            </div>
          )
        } 
      </>
    )
  }

  if (isGanttFullScreenMode){ 
    return (
      <>
        <Dialog
          visible={isGanttFullScreenMode}
          modal 
          header="Full Screen Mode View"
          className="DailyOperationAssetDetailTree dialog-header-background-blue"
          closable={false} 
          maximized={true}
          draggable={false}
          position="right" 
        >
          <div className='pt-3'>
            {screenRender()}
          </div>
        </Dialog>
      </> 
    )
  }
  return (
    <>
      <Card style={{borderRadius: '1%'}}>  
        <CardBody> 
          {screenRender()}
        </CardBody>
      </Card>      
    </>
      ); 
  };

export default DailyOperationProductionTrendChart